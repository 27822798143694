import { ComponentStyleConfig } from '@chakra-ui/react';

export const allTextVariants: ComponentStyleConfig = {
  variants: {
    'heading-1': {
      fontWeight: 'bold',
      fontSize: 'heading-1',
      lineHeight: 'heading-1',
      letterSpacing: '-2%',
    },
    'heading-2': {
      fontWeight: 'bold',
      fontSize: 'heading-2',
      lineHeight: 'heading-2',
      letterSpacing: '-2%',
    },
    'heading-3': {
      fontWeight: 'bold',
      fontSize: 'heading-3',
      lineHeight: 'heading-3',
      letterSpacing: '-2%',
    },
    'heading-4': {
      fontWeight: 'bold',
      fontSize: 'heading-4',
      lineHeight: 'heading-4',
      letterSpacing: '-2%',
    },

    // Subheading

    'subheading-1': {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '-1%',
    },
    'subheading-2': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-1%',
    },

    // Body texts

    'body-1': {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '-1%',
    },
    'body-2': {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-1%',
    },
    'body-3': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-1%',
    },
    'body-4': {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '-1%',
    },

    'text-xl': {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '-2%',
    },
    'text-lg': {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '-1%',
    },
    'text-md': {
      fontWeight: 'regular',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-1%',
    },
    'text-sm': {
      fontWeight: 'regular',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '-1%',
    },

    smallcaps: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '0%',
      textTransform: 'uppercase',
    },

    menu: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-1%',
    },
  },
};
