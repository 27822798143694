import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';

import FallbackSpinner from 'components/FallbackSpinner';

import useAuth from 'hooks/useAuth';
import usePersistedState from 'hooks/usePersistedState';
import { SidebarProvider } from 'hooks/useSidebar';
import { AllowedRoles } from 'types/user';

const AuthRoutes = lazy(() => import('./AuthRoutes'));
const AppRoutes = lazy(() => import('./AppRoutes'));

const Routes: React.FC = () => {
  const { pathname } = useLocation();

  const isClassroomRoute = useRouteMatch('/checkout/classroom/:id');

  const [_, setClassroomRoute] = usePersistedState('classroomRoute', null);

  const { authenticated, checkRoles, user, validing } = useAuth();

  useEffect(() => {
    if (isClassroomRoute) {
      setClassroomRoute(isClassroomRoute.params?.id);
    }
  }, [isClassroomRoute, setClassroomRoute]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }, 30);

    return () => clearTimeout(timer);
  }, [pathname]);

  if (validing) {
    return <FallbackSpinner />;
  }

  if (user && user.roles && !checkRoles(AllowedRoles)) {
    window.location.href = 'https://cursology.com.br';
    return <FallbackSpinner />;
  }

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <SidebarProvider>
        {authenticated ? <AppRoutes /> : <AuthRoutes />}
      </SidebarProvider>
    </Suspense>
  );
};

export default withRouter(Routes);
