import React, { useState, useCallback, useEffect } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  HStack,
  Flex,
  IconButton,
  Icon,
  Text,
  useToast,
} from '@chakra-ui/react';

import { Checkout } from 'types/checkout';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import { Content } from 'components/Page';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import { getBadgeBestOffer } from 'shared/checkout/getBadgeBestOffer';

export interface DefaultCheckoutSortProps extends ModalRootProps {
  data: {
    id: number;
  };
}

const ModalDefaultCheckoutsSort: React.FC<DefaultCheckoutSortProps> = ({
  data: initialData,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const [data, setData] = useState<Checkout[]>([]);
  const [checkouts, setCheckouts] = useState<Checkout[]>([]);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const getData = useCallback(async () => {
    if (!initialData?.id) {
      return;
    }

    setLoading(true);
    setData([]);

    try {
      const response = await api.get(`/classroom/${initialData.id}/paylink`, {
        params: {
          'filter[default]': true,
          sort: 'platform_position',
        },
      });

      const defaultCheckouts = response.data;

      setData(defaultCheckouts.data);

      setCheckouts(defaultCheckouts.data.paylinks);
    } catch (err) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [initialData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleConfirm = async (): Promise<void> => {
    setSaving(true);

    const checkoutsToSave = checkouts.map((item, index) => {
      return {
        id: item.id,
        position: index + 1,
      };
    });

    try {
      await api.post(`/classroom/${initialData.id}/position`, checkoutsToSave);

      toast({
        title: 'Ofertas ordenadas com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });

      handleClose();

      if (onConfirm) onConfirm();
    } catch (err) {
      toast({
        title: 'Não foi possível ordenar as ofertas!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleChangeMethodPosition = useCallback(
    (direction: 'up' | 'down', index: number) => {
      const newOrder = [...checkouts];

      if (direction === 'up') {
        [newOrder[index], newOrder[index - 1]] = [
          newOrder[index - 1],
          newOrder[index],
        ];
      }

      if (direction === 'down') {
        [newOrder[index], newOrder[index + 1]] = [
          newOrder[index + 1],
          newOrder[index],
        ];
      }

      setCheckouts(newOrder);
    },
    [checkouts],
  );

  const renderTableData = useCallback(() => {
    return (
      <Tbody>
        <AnimatePresence>
          {!loading &&
            checkouts.map((item, i) => (
              <motion.tr
                key={item.id}
                layout
                transition={{
                  duration: 0.3,
                  type: 'spring',
                }}
              >
                <Td>
                  <Flex alignItems="center">
                    <Text fontWeight="bold">{i + 1}</Text>

                    <Flex
                      flexDirection="column"
                      justifyContent="space-around"
                      alignItems="center"
                      paddingY="8px"
                      paddingX="8px"
                      gap={1}
                    >
                      <IconButton
                        size="xs"
                        icon={<Icon as={TriangleUpIcon} w={4} h={4} />}
                        variant="link"
                        onClick={() => handleChangeMethodPosition('up', i)}
                        isDisabled={i === 0}
                      />

                      <IconButton
                        size="xs"
                        icon={<Icon as={TriangleDownIcon} w={4} h={4} />}
                        variant="link"
                        onClick={() => handleChangeMethodPosition('down', i)}
                        isDisabled={i === checkouts.length - 1}
                      />
                    </Flex>
                  </Flex>
                </Td>

                <Td>
                  <Flex alignItems="center">
                    {item.name}

                    {i === 0 && getBadgeBestOffer()}
                  </Flex>
                </Td>

                <Td>{item.code}</Td>

                <Td>
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(item.price || 0)}
                </Td>
              </motion.tr>
            ))}

          {loading && (
            <Tr>
              <Td noData colSpan={1000}>
                <Loader />
              </Td>
            </Tr>
          )}

          {!loading && data.length === 0 && (
            <Tr>
              <Td noData colSpan={1000} className="text-center">
                Nenhuma oferta padrão encontrada.
              </Td>
            </Tr>
          )}
        </AnimatePresence>
      </Tbody>
    );
  }, [checkouts, data, handleChangeMethodPosition, loading]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="3xl">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          Ordenar ofertas
          <Text variant="text-sm" fontWeight="normal">
            Aqui serão exibidas apenas as ofertas que foram definidas para
            aparecer na página de vendas.
          </Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Content noPadding>
            <Table>
              <Thead>
                <Tr>
                  <Th>Posição</Th>

                  <Th>Nome</Th>

                  <Th>Código</Th>

                  <Th>Preço</Th>
                </Tr>
              </Thead>

              {renderTableData()}
            </Table>
          </Content>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="1">
            <Button colorScheme="red" onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              onClick={handleConfirm}
              isLoading={saving || loading}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDefaultCheckoutsSort;
