export type Roles =
  | 'super_admin'
  | 'platform_support'
  | 'vendor_admin'
  | 'vendor_support'
  | 'accounting';

export const AllowedRoles: Roles[] = [
  'super_admin',
  'platform_support',
  'vendor_admin',
  'vendor_support',
  'accounting',
];

export type Status = 'A' | 'P' | 'B';

export type User = {
  id: number;
  name: string;
  email: string;
  status: Status;
  phone_number: string | null;
  role: Roles[];
  created_at: Date;
  updated_at: Date;
  account_sms: string | null;
  account_push: string | null;
  account_email: string | null;
};

export type Admin = User;
