import React, { useState, useCallback, useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  TableContainer,
  Text,
  Button,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Tag,
} from '@chakra-ui/react';

import { Account } from 'types/account';
import {
  Pagination as PaginationType,
  ServicePagination,
} from 'types/pagination';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { bankAccountTypes } from 'shared/bank/types';
import { documentFormat } from 'shared/documentFormat';

const ModalAccountLookup: React.FC<ModalRootProps> = ({
  // data = {},
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (account: Account): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm(account);
      handleClose();
    }
  };

  const [data, setData] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<PaginationType>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/account', {
        params: {
          'filter[keyword]': keyword,
          'filter[has_default_bank_account]': true,
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const users = response.data;

      setData(users.data);
      setServicePagination(users.meta);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, keyword]);

  useEffect(() => {
    getData();
  }, [getData]);

  const findBankType: string = (bankType: string) => {
    const type = bankAccountTypes.find(
      (typeAccountType) => typeAccountType.value === bankType,
    );

    return type ? type.label : '-';
  };

  return (
    <Modal {...restProps} scrollBehavior="inside" size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecione uma conta</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} my={3}>
            <HStack>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack>
              <Input
                type="text"
                placeholder="Pesquisar"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </HStack>
          </SimpleGrid>

          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Conta</Th>
                  <Th>Banco</Th>
                  <Th>Tipo de conta</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!loading &&
                  data.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        <Text fontWeight="bold">{item.name}</Text>
                        <Text>
                          {item.bank_account?.document_number
                            ? documentFormat(item.bank_account?.document_number)
                            : '-'}
                        </Text>
                      </Td>

                      <Td>
                        <Text>
                          {`${item.bank_account?.bank?.code || '000'} - ${
                            item.bank_account?.bank?.name ||
                            'Banco não encontrado'
                          }`}
                        </Text>
                        <Text>
                          <Tag size="sm">
                            <Text as="span" fontWeight="bold" fontSize="0.8rem">
                              AG
                            </Text>
                            : {item.bank_account?.agency_number || '0000'}
                          </Tag>{' '}
                          <Tag size="sm">
                            <Text as="span" fontWeight="bold" fontSize="0.8rem">
                              CT
                            </Text>
                            {`: ${
                              item.bank_account?.account_number || '0000000000'
                            }-${item.bank_account?.account_vd || '0'}`}
                          </Tag>
                        </Text>
                      </Td>

                      {/* <Td>Conta corrente</Td> */}
                      <Td>{findBankType(item.bank_account?.type || '')}</Td>

                      <Td>
                        <Button
                          colorScheme="yellow"
                          onClick={() => handleConfirm(item)}
                          size="sm"
                        >
                          Selecionar
                        </Button>
                      </Td>
                    </Tr>
                  ))}

                {loading && (
                  <Tr>
                    <Td colSpan={10}>
                      <Loader />
                    </Td>
                  </Tr>
                )}

                {!loading && data.length === 0 && (
                  <Tr>
                    <Td colSpan={10} className="text-center">
                      Nenhum resultado encontrado
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAccountLookup;
