import React from 'react';

import { motion, MotionProps } from 'framer-motion';

import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

import { ChevronUp as UpCircleIcon } from '@styled-icons/entypo/ChevronUp';

const variants = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

type DropdownIndicatorProps = MotionProps &
  IconProps & {
    isOpen: boolean;
  };

const UpCircleIconMotion = motion<DropdownIndicatorProps>(Icon);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({
  isOpen,
  ...restProps
}) => {
  return (
    <UpCircleIconMotion
      as={UpCircleIcon}
      animate={isOpen ? 'open' : 'closed'}
      variants={variants}
      w={5}
      h={5}
      color="gray.500"
      {...restProps}
    />
  );
};

export default DropdownIndicator;
