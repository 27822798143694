import React, { useState } from 'react';

import {
  Text,
  Button,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tag,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { Course } from 'types/course';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

interface ServicePagination {
  last_page: number;
}

interface Pagination {
  per_page: number;
  page: number;
}
const ModalDiscountCouponLookup: React.FC<ModalRootProps> = ({
  // data = {},
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (product: Course): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm(product as Course);
      handleClose();
    }
  };

  const [data] = useState([
    {
      id: 1,
      type: '%',
      value: '10',
      description: '10% off',
      start_date: '2020-01-01',
      end_date: '2020-12-31',
      coupon_code: '10OFF',
    },
  ]);

  const [loading] = useState(false);

  const [, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination] = useState<ServicePagination>({ last_page: 1 });

  // const getData = useCallback(async () => {
  //   setLoading(true);
  //   setData([]);

  //   try {
  //     const response = await api.get('/course/wordpress', {
  //       params: {
  //         'filter[keyword]': keyword,

  //         page: pagination.page,
  //         per_page: pagination.per_page,
  //       },
  //     });

  //     const users = response.data;

  //     setData(users.data);
  //     setServicePagination(users.meta);
  //   } catch (err) {
  //     setData([]);
  //     setServicePagination({ last_page: 1 });
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [pagination, keyword]);

  // useEffect(() => {
  //   getData();
  // }, [getData]);

  return (
    <Drawer {...restProps} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Pesquisar cupom de desconto</DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          <Flex direction="column" marginBottom="4" color="gray.600">
            <Text>Somente são listados os cupons do tipo de Checkout.</Text>

            <Text>
              Cupons de Produto, Instrutor ou de plataforma, precisam ser
              gerenciados pela funcionalidade de <Tag>Cupom de Desconto</Tag>,
              no menu de Marketing.
            </Text>
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} my={3}>
            <HStack>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack>
              <Input
                type="text"
                placeholder="Pesquisar"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </HStack>
          </SimpleGrid>

          <Table>
            <Thead>
              <Tr>
                <Th>Cupom</Th>
                <Th>Tipo</Th>
                <Th>Valor</Th>
                <Th>Vigência</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loading &&
                data.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      <Text>{item.coupon_code}</Text>
                    </Td>

                    <Td>{item.type}</Td>

                    <Td>
                      <Tag colorScheme="oceanBlue">
                        {item.value}
                        {item.type}
                      </Tag>
                    </Td>

                    <Td>
                      <VStack dir="column" align="flex-start">
                        <Tag colorScheme="oceanBlue" fontWeight="bold">
                          Início:{' '}
                          {new Date(item.start_date).toLocaleDateString()}
                        </Tag>

                        <Tag colorScheme="oceanBlue" fontWeight="bold">
                          Término:{' '}
                          {new Date(item.end_date).toLocaleDateString()}
                        </Tag>
                      </VStack>
                    </Td>

                    <Td>
                      <Button
                        colorScheme="yellow"
                        size="xs"
                        onClick={() => handleConfirm(item)}
                      >
                        Selecionar
                      </Button>
                    </Td>
                  </Tr>
                ))}

              {loading && (
                <Tr>
                  <Td colSpan={10}>
                    <Loader />
                  </Td>
                </Tr>
              )}

              {!loading && data.length === 0 && (
                <Tr>
                  <Td colSpan={10} className="text-center">
                    Nenhum resultado encontrado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </DrawerBody>

        <DrawerFooter justifyContent="center">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </DrawerFooter>

        {/* <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter> */}
      </DrawerContent>
    </Drawer>
  );
};

export default ModalDiscountCouponLookup;
