import React from 'react';

import { createIcon } from '@chakra-ui/icons';

import { ReactComponent as ActivityIconSVG } from 'assets/icons/activity.svg';
import { ReactComponent as AtAssignSVG } from 'assets/icons/at-assign.svg';
import { ReactComponent as AwardSVG } from 'assets/icons/award.svg';
import { ReactComponent as BagSVG } from 'assets/icons/bag.svg';
import { ReactComponent as BuySVG } from 'assets/icons/buy.svg';
import { ReactComponent as ChartSVG } from 'assets/icons/chart.svg';
import { ReactComponent as ChatSVG } from 'assets/icons/chat.svg';
import { ReactComponent as ChevronLeftSVG } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ClockIconSVG } from 'assets/icons/clock.svg';
import { ReactComponent as CloseIconSVG } from 'assets/icons/close.svg';
import { ReactComponent as Close2024SVG } from 'assets/icons/close-2024.svg';
import { ReactComponent as DangerSVG } from 'assets/icons/danger.svg';
import { ReactComponent as DiscoverySVG } from 'assets/icons/discovery.svg';
import { ReactComponent as DollarSVG } from 'assets/icons/dollar.svg';
import { ReactComponent as Dollar2024SVG } from 'assets/icons/dollar-2024.svg';
import { ReactComponent as GridSVG } from 'assets/icons/grid.svg';
import { ReactComponent as HideSVG } from 'assets/icons/hide.svg';
import { ReactComponent as HomeSVG } from 'assets/icons/home.svg';
import { ReactComponent as LeftArrowSVG } from 'assets/icons/left-arrow.svg';
import { ReactComponent as LockSVG } from 'assets/icons/lock.svg';
import { ReactComponent as MarketingSVG } from 'assets/icons/marketing.svg';
import { ReactComponent as PlaySVG } from 'assets/icons/play.svg';
import { ReactComponent as PaperSVG } from 'assets/icons/paper.svg';
import { ReactComponent as RightArrowSVG } from 'assets/icons/right-arrow.svg';
import { ReactComponent as SettingSVG } from 'assets/icons/setting.svg';
import { ReactComponent as Setting2024SVG } from 'assets/icons/setting-2024.svg';
import { ReactComponent as ShowSVG } from 'assets/icons/show.svg';
import { ReactComponent as StarIconSVG } from 'assets/icons/star.svg';
import { ReactComponent as StatisticsMonitorSVG } from 'assets/icons/statistics-monitor.svg';
import { ReactComponent as SubscriptionSVG } from 'assets/icons/subscription.svg';
import { ReactComponent as SuccessSVG } from 'assets/icons/success.svg';
import { ReactComponent as TeacherSVG } from 'assets/icons/teacher.svg';
import { ReactComponent as Teacher2024SVG } from 'assets/icons/teacher-2024.svg';
import { ReactComponent as TicketSVG } from 'assets/icons/ticket.svg';
import { ReactComponent as UnlockSVG } from 'assets/icons/unlock.svg';
import { ReactComponent as UpCircleSVG } from 'assets/icons/up-circle.svg';
import { ReactComponent as CursologyLogoSymbolSVG } from 'assets/icons/cursology-logo-symbol.svg';

export const HomeIcon = createIcon({
  displayName: 'Home',
  viewBox: '0 0 24 24',
  path: <HomeSVG />,
});

export const ActivityIcon = createIcon({
  displayName: 'Activity',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: <ActivityIconSVG />,
});

export const ClockIcon = createIcon({
  displayName: 'Clock',
  viewBox: '0 0 24 24',
  path: <ClockIconSVG />,
});

export const CloseIcon = createIcon({
  displayName: 'Close',
  viewBox: '0 0 24 24',
  path: <CloseIconSVG />,
});

export const StarIcon = createIcon({
  displayName: 'Star',
  viewBox: '0 0 18 18',
  path: <StarIconSVG />,
});

export const UpCircleIcon = createIcon({
  displayName: 'UpCircle',
  viewBox: '0 0 32 32',
  path: <UpCircleSVG />,
});

export const PaperIcon = createIcon({
  displayName: 'UpCircle',
  viewBox: '0 0 18 20',
  path: <PaperSVG />,
});

export const ChartIcon = createIcon({
  displayName: 'Chart',
  viewBox: '0 0 20 20',
  path: <ChartSVG />,
});

export const TicketIcon = createIcon({
  displayName: 'Ticket',
  viewBox: '0 0 20 18',
  path: <TicketSVG />,
});

export const SubscriptionIcon = createIcon({
  displayName: 'Subscription',
  viewBox: '0 0 20 20',
  path: <SubscriptionSVG />,
});

export const DollarIcon = createIcon({
  displayName: 'Dollar',
  viewBox: '0 0 20 20',
  path: <DollarSVG />,
});

export const BuyIcon = createIcon({
  displayName: 'Buy',
  viewBox: '0 0 20 19',
  path: <BuySVG />,
});

export const TeacherIcon = createIcon({
  displayName: 'Teacher',
  viewBox: '0 0 23 23',
  path: <TeacherSVG />,
});

export const BagIcon = createIcon({
  displayName: 'Bag',
  viewBox: '0 0 24 24',
  path: <BagSVG />,
});

export const SettingIcon = createIcon({
  displayName: 'Setting',
  viewBox: '0 0 20 19',
  path: <SettingSVG />,
});

export const AwardIcon = createIcon({
  displayName: 'Award',
  viewBox: '0 0 14 21',
  path: <AwardSVG />,
});

export const SuccessIcon = createIcon({
  displayName: 'Success',
  viewBox: '0 0 24 24',
  path: <SuccessSVG />,
});

export const DangerIcon = createIcon({
  displayName: 'Danger',
  viewBox: '0 0 24 24',
  path: <DangerSVG />,
});
export const AtAssignIcon = createIcon({
  displayName: 'AtAssign',
  viewBox: '0 0 20 20',
  path: <AtAssignSVG />,
});

export const LockIcon = createIcon({
  displayName: 'Lock',
  viewBox: '0 0 18 20',
  path: <LockSVG />,
});

export const UnlockIcon = createIcon({
  displayName: 'Unlock',
  viewBox: '0 0 18 21',
  path: <UnlockSVG />,
});

export const ShowIcon = createIcon({
  displayName: 'Show',
  viewBox: '0 0 20 17',
  path: <ShowSVG />,
});

export const HideIcon = createIcon({
  displayName: 'Hide',
  viewBox: '0 0 20 18',
  path: <HideSVG />,
});

export const RightArrowIcon = createIcon({
  displayName: 'RightArrow',
  viewBox: '0 0 18 14',
  path: <RightArrowSVG />,
});

export const LeftArrowIcon = createIcon({
  displayName: 'LeftArrow',
  viewBox: '0 0 18 14',
  path: <LeftArrowSVG />,
});

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeft',
  viewBox: '0 0 8 12',
  path: <ChevronLeftSVG />,
});

export const CursologyLogoSymbolIcon = createIcon({
  displayName: 'CursologyLogoSymbol',
  viewBox: '0 0 20 20',
  path: <CursologyLogoSymbolSVG />,
});

export const ChatIcon = createIcon({
  displayName: 'Chat',
  viewBox: '0 0 20 20',
  path: <ChatSVG />,
});

export const DiscoveryIcon = createIcon({
  displayName: 'Discovery',
  viewBox: '0 0 20 20',
  path: <DiscoverySVG />,
});

export const Dollar2024Icon = createIcon({
  displayName: 'Dollar2024',
  viewBox: '0 0 20 20',
  path: <Dollar2024SVG />,
});

export const GridIcon = createIcon({
  displayName: 'Grid',
  viewBox: '0 0 20 20',
  path: <GridSVG />,
});

export const MarketingIcon = createIcon({
  displayName: 'Marketing',
  viewBox: '0 0 20 20',
  path: <MarketingSVG />,
});

export const Setting2024Icon = createIcon({
  displayName: 'Setting2024',
  viewBox: '0 0 20 20',
  path: <Setting2024SVG />,
});

export const StatisticsMonitorIcon = createIcon({
  displayName: 'StatisticsMonitor',
  viewBox: '0 0 20 20',
  path: <StatisticsMonitorSVG />,
});

export const Teacher2024Icon = createIcon({
  displayName: 'Teacher2024',
  viewBox: '0 0 20 20',
  path: <Teacher2024SVG />,
});

export const PlayIcon = createIcon({
  displayName: 'Play',
  viewBox: '0 0 20 20',
  path: <PlaySVG />,
});

export const Close2024Icon = createIcon({
  displayName: 'Close 2024',
  viewBox: '0 0 16 16',
  path: <Close2024SVG />,
});
