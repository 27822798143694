import React, { createContext, useState, useCallback } from 'react';

import { useMediaQuery } from '@chakra-ui/react';

import usePersistedState from 'hooks/usePersistedState';

export interface ISidebar {
  open: boolean;
  isSticked: boolean;
  isMobile: boolean;
  isDark: boolean;
  variant: 'sidebar' | 'drawer';
  toggleTheme(): void;
  toggleSidebar(): void;
  toggleStick(): void;
  openSidebar(): void;
  closeSidebar(): void;
}

const SidebarContext = createContext({} as ISidebar);

export const SidebarProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isSticked, setIsSticked] = usePersistedState<boolean>(
    'sidebarSticked',
    false,
  );

  const [isDark, setIsDark] = usePersistedState<boolean>('sidebarDark', false);
  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const toggleSidebar = useCallback((): void => {
    setOpen(!open);
  }, [open]);

  const toggleStick = useCallback((): void => {
    setIsSticked(!isSticked);
  }, [isSticked, setIsSticked]);

  const toggleTheme = useCallback((): void => {
    setIsDark(!isDark);
  }, [isDark, setIsDark]);

  const closeSidebar = useCallback((): void => {
    if (isMobile) {
      setIsSticked(false);
    }
    setOpen(false);
  }, [isMobile, setIsSticked]);

  const openSidebar = useCallback((): void => {
    if (isMobile) {
      setIsSticked(true);
    }
    setOpen(true);
  }, [isMobile, setIsSticked]);

  return (
    <SidebarContext.Provider
      value={{
        open,
        toggleSidebar,
        toggleTheme,
        isSticked,
        isMobile,
        isDark,
        toggleStick,
        variant: 'sidebar',
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
